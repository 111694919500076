.dashboard-container {
    display: grid;
    grid-gap: 20px;
    padding: 20px;
    justify-content: center; /* Centers the cards */
}

.dashboard-container.four-cards {
    grid-template-columns: repeat(4, 1fr);
}

.dashboard-container.two-cards {
    grid-template-columns: repeat(2, 230px); /* Two cards with 230px width */
    justify-content: center;
}

.dashboard-card {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    height: 115px;
    color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    width: 100%; /* Full width for each card */
    max-width: 480px; /* Maximum width when there are more than 2 cards */
}

.dashboard-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.card-content-photo-dashboard {
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.icon-section {
    font-size: 30px;
    margin-right: 20px;
}

.text-section h3 {
    margin: 0;
    font-size: 16px;
}

.text-section p {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
}
