.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }

   to {
      transform: rotate(360deg);
   }
}

a {
   text-decoration: none !important;
}

.hide {
   display: none !important;
}

.show {
   display: block !important;
}

.dropzone {
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px;
   border-width: 2px;
   border-radius: 2px;
   border-color: #eeeeee;
   border-style: dashed;
   background-color: #fafafa;
   color: #bdbdbd;
   outline: none;
   transition: border 0.24s ease-in-out;
}

/* 
.dropzone_img {
  display: block;
  width: 100px;
  padding: 10px;
  height: 100%;
}

.dropzone_thumb {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.dropzone_thum_img {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin: 5px;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
} */

.dropzone_thum_img {
   display: inline-block;
   border: 1px solid #ccc;
   /* Add border for the container */
   padding: 5px;
   /* Add padding around the container */
   margin: 5px;
   /* Add space between containers */
}

.dropzone_thumb {
   display: flex;
   align-items: center;
   /* Center items vertically */
}

.dropzone_img {
   width: 100px;
   height: auto;
   margin-right: 10px;
   /* Add space between image and radio button */
   margin-left: 20px;
}

.thumbcontainer {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.react-dropdown-select-dropdown {
   width: 100% !important;
}

.react-dropdown-select-input {
   font-size: medium;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
   margin-bottom: 0px !important;
}

.error {
   color: red;
}

.list {
   user-select: none;
   display: grid;
   grid-template-columns: auto auto auto auto;
   grid-gap: 40px;
}

.item {
   display: block;
   justify-content: center;
   align-items: center;
   text-align: center;
   color: Black;
   padding-top: 30px;
   height: 150px;
   width: 200px;
   cursor: grab;
   font-size: 20px;
   user-select: none;
   border: 1px solid #adadad;
}

.item img {
   width: auto;
   height: 70px;
   cursor: grab;
}

.dragged {
   background-color: rgb(37, 37, 197);
}

@media (min-width: 600px) {
   .list {
      grid-gap: 50px;
   }
}

.card-border {
   box-shadow: 0 1px 2px 0 rgb(0 0 0 / 88%) !important;
}

.antformrange {
   display: flex !important;
}

.close_img {
   line-height: 12px;
   width: 18px;
   font-size: 15px;
   font-family: tahoma;
   margin-top: 1px;
   margin-right: 2px;
   position: absolute;
   cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type="number"] {
   -moz-appearance: textfield;
}

.ant-tabs-dropdown {
   z-index: 999999;
}

.list .item p {
   font-size: 14px;
}

.dragBtn {
   width: 40px;
   height: 40px;
   text-align: center;
   line-height: 40px;
}

.swipeable-list-item__content {
   justify-content: space-between !important;
}

.borderBtn {
   border-width: 2px !important;
   border-color: #8b8b8b !important;
   height: 50px;
   padding: 4px;
}

.ReactCrop__child-wrapper {
   display: block;
   position: absolute;
}

.css-1cbdnml-InputComponent {
   line-height: inherit;
   border: none;
   margin-left: 5px;
   background: transparent;
   padding: 0;
   width: calc(13ch + 5px);
   /* font-size: smller; */
}

/* CustomInput.css */
.custom-input-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   width: 475px;
   /* Set input box width */
}

.tag-container {
   display: flex;
   overflow-x: auto;
   /* Enable horizontal scrolling */
   width: 100%;
   padding: 8px 0;
   /* Adjust padding as needed */
}

.tag-container .ant-tag {
   margin-right: 8px;
   /* Adjust margin between tags */
}

.welcome-page {
   margin-top: 15%;
   text-align: center;
}

.welcome-page .welcome-content h2 {
   margin-top: 25px;
   font-size: 45px;
   color: #6e6e6e !important;
}

.welcome-page .welcome-content strong {
   margin-top: 25px;
   font-size: 45px;
   color: #f7931e !important;
}

.horizontal-list {
   list-style: none;
   /* Remove default list styling */
   display: flex;
   /* Use Flexbox for horizontal layout */
   padding: 0;
   /* Remove default padding */
   margin: 0;
   /* Remove default margin */
}

.horizontal-list li {
   margin-right: 20px;
   /* Space between items */
}

.horizontal-list li:last-child {
   margin-right: 0;
   /* Remove right margin for the last item */
}

.horizontal-list a {
   text-decoration: none;
   /* Remove underline from links */
   color: #000;
   /* Text color */
   font-weight: bold;
   /* Font weight */
}

.horizontal-list a:hover {
   color: #f7931e;
}

/* Antd Table Row background Color (Common for all Antd Tables) */
.ant-table-tbody tr:nth-child(odd) {
   /* background-color: rgba(153, 153, 153, 0.2); */
   background-color: #f4f7f6;
}

.ant-table-tbody tr .ant-table-column-sort {
   background: transparent;
}

.container-fluid-staff {
   margin-top: 10px;
}

.grid-container {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(5, auto);
   /* gap: 10px;
   padding: 10px; */
}

.grid-item {
   /* background-color: #f4f4f4; */
   /* border: 1px solid #ddd; */
   padding: 5px;
   text-align: center;
}

.col-span-1 {
   grid-column: span 1;
   text-align: left;
}

.col-span-2 {
   grid-column: span 2;
   text-align: left;
}

.row-span-2 {
   grid-row: span 2;
}

.start-col-3 {
   grid-column-start: 3;
   text-align: left;
}

.photographer.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.photographer.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
   width: 140px;
   height: 140px;
   text-align: center;
   vertical-align: top;
   background-color: rgba(0, 0, 0, 0.02);
   border: 1px dashed #d9d9d9;
   border-radius: 8px;
   cursor: pointer;
   transition: border-color 0.3s;
}

.job.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.job.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
   width: 290px;
   height: 250px;
   text-align: center;
   vertical-align: top;
   background-color: rgba(0, 0, 0, 0.02);
   border: 1px dashed #d9d9d9;
   border-radius: 8px;
   cursor: pointer;
   transition: border-color 0.3s;
}

.staff :where(.css-dev-only-do-not-override-17seli4).ant-tooltip .ant-tooltip-inner {
   min-width: 1em;
   min-height: 32px;
   padding: 6px 8px;
   color: #fff;
   text-align: start;
   text-decoration: none;
   word-wrap: break-word;
   background-color: rgba(0, 0, 0, 0.85);
   border-radius: 6px;
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
   box-sizing: border-box;
   display: none;
}

.staff :where(.css-dev-only-do-not-override-17seli4).ant-tooltip-hidden {
   display: none;
}

.staff .ant-tooltip .css-dev-only-do-not-override-17seli4 .ant-tooltip-placement-top{
   --arrow-x: 0px;
   --arrow-y: 6px;
   inset: auto auto -165.75px 1052.5px;
   box-sizing: border-box;
   display: none;
   pointer-events: none;
}

.job :where(.css-dev-only-do-not-override-17seli4).ant-tooltip .ant-tooltip-inner {
   min-width: 1em;
   min-height: 32px;
   padding: 6px 8px;
   color: #fff;
   text-align: start;
   text-decoration: none;
   word-wrap: break-word;
   background-color: rgba(0, 0, 0, 0.85);
   border-radius: 6px;
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
   box-sizing: border-box;
   display: none;
}

.job :where(.css-dev-only-do-not-override-17seli4).ant-tooltip-hidden {
   display: none;
}

.job .ant-tooltip .css-dev-only-do-not-override-17seli4 .ant-tooltip-placement-top{
   --arrow-x: 0px;
   --arrow-y: 6px;
   inset: auto auto -165.75px 1052.5px;
   box-sizing: border-box;
   display: none;
   pointer-events: none;
}

.photographer :where(.css-dev-only-do-not-override-17seli4).ant-tooltip .ant-tooltip-inner {
   min-width: 1em;
   min-height: 32px;
   padding: 6px 8px;
   color: #fff;
   text-align: start;
   text-decoration: none;
   word-wrap: break-word;
   background-color: rgba(0, 0, 0, 0.85);
   border-radius: 6px;
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
   box-sizing: border-box;
   display: none;
}

.photographer :where(.css-dev-only-do-not-override-17seli4).ant-tooltip-hidden {
   display: none;
}

.photographer .ant-tooltip .css-dev-only-do-not-override-17seli4 .ant-tooltip-placement-top{
   --arrow-x: 0px;
   --arrow-y: 6px;
   inset: auto auto -165.75px 1052.5px;
   box-sizing: border-box;
   display: none;
   pointer-events: none;
}

:where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card, :where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card, :where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle, :where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle {
   display: flex;
   flex-wrap: wrap;
   height: 140px;
   width: 250px;
}

.job.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
.job.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
.job.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item,
.job.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
   height: 240px;
   width: 250px;
   border-color: green;
   margin: 0;
}

.grid-container-job {
   display: grid;
   grid-template-columns: 1.8fr 1.8fr 1.5fr;
   gap: 3px;
   /* Adjust as needed */
   padding: 10px;
   /* Adjust as needed */
}

.grid-item-job {
   display: flex;
   flex-direction: column;
   gap: 10px;
   /* Adjust as needed */
}

.grid-item-job label {
   margin-right: 10px;
}

.upload {
   grid-row: span 6;
}

/* Add this to your CSS file or inside a <style> tag */
.inline-label-input {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
}

.inline-label-input label {
   margin-right: 10px;
}

.rooms .ant-card-body {
   padding: 0px;
   border-radius: 0 0 8px 8px;
}

.rooms {
   background-color: #f6f6f6;
   transition: background-color 0.3s ease, color 0.3s ease;
}

.rooms:hover {
   background-color: #f7931e;
   color: white;
}

.rooms:hover .icon-wrapper {
   background-color: white;
}

.icon-wrapper {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background-color: white;
   margin-left: 4px;
}

.icon-wrapper .edit-icon {
   color: #000;
   /* Change this to your preferred edit icon color */
}

.icon-wrapper .delete-icon {
   color: red;
}

.image-gallery-image {
   position: relative;
   margin-bottom: 10px;
}

.image-gallery-icons {
   position: absolute;
   top: -45px;
   right: 10px;
   display: flex;
   align-items: center;
}

.image-gallery-icons-photographer {
   position: absolute;
   top: -40px;
   right: 365px;
   display: flex;
   align-items: center;
}

.ant-tabs-tab {
   padding: 8px;
}

.ant-tabs-card .ant-tabs-content-holder {
   border: none;
}

.ant-btn-danger {
   background-color: red;
   border-color: red;
}

.image-gallery-image {
   position: relative;
   margin-bottom: 10px;
}

.image-gallery-icons {
   position: absolute;
   top: -45px;
   right: 10px;
   display: flex;
   align-items: center;
}

.ant-btn-danger {
   background-color: red;
   border-color: red;
}

.carousel-container {
   width: 15%;
   padding: 10px;
   position: relative;
   text-align: center;
}

.thumbnail-container {
   padding: 10px;
}

.thumbnail {
   width: 100%;
   cursor: pointer;
}

.custom-arrow {
   width: 100%;
   font-weight: bold;
   color: #f7931e;
}

.pagination-container {
   margin-top: 20px;
   text-align: center;
}

.image-gallery-wrapper {
   width: 60%;
   padding: 10px;
}

.image-gallery-image img {
   width: 100%;
}

.image-gallery-icons {
   position: absolute;
   top: -40px;
   right: 15px;
   display: flex;
   align-items: center;
}

.input-fields {
   width: 25%;
   padding: 10px;
   display: flex;
   flex-direction: column;
}

.input-fields label {
   margin-top: 10px;
}

.input-box-size {
   width: 70%;
}

.item-category-card:hover {
   background-color: #f7931e;
}

.item-category-card:hover .item-category-card-title {
   color: white;
}

:where(.css-dev-only-do-not-override-17seli4) .ant-image-preview-footer {
   position: fixed;
   bottom: 32px;
   left: 0;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   display: none;
   color: rgba(255, 255, 255, 0.65);
}


.heading {
   margin-bottom: 40px;
   font-size: 2.5em;
   z-index: 1;
}

.heading-container {
   background-color: #f7931e;
   color: white;
   text-align: center;
   padding: 15px 0;
}

.client-heading {
   margin: 0;
   font-size: 2em;
}

.subheading {
   margin: 0;
   font-size: 1.2em;
}

.client-portal-home-container {
   padding: 20px;
   background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(211, 211, 211, 0.8)), url('assets/images/home.png');
   background-size: cover;
   background-position: center;
   height: 100vh;
}

.client-portal-home-card-container {
   display: flex;
   justify-content: center;
   gap: 20px;
   z-index: 1;
   flex-wrap: wrap;
}

.client-portal-home-card {
   width: 300px;
   height: 260px;
   /* Adjust height as needed */
   background-color: white;
   border-radius: 10px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 20px;
   /* Padding inside the card */
   overflow: hidden;
   transition: transform 0.3s, box-shadow 0.3s;
   cursor: pointer;
}

.client-portal-home-card:hover {
   transform: scale(1.05);
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.client-portal-home-card-image {
   width: 100%;
   height: 200px;
   /* Adjust height as needed */
   background-size: cover;
   background-position: center;
   border-radius: 10px;
   margin-bottom: 15px;
   /* Space between image and title */
}

.client-portal-home-card-title {
   text-align: center;
   background-color: #f7931e;
   color: white;
   padding: 10px;
   border-radius: 5px;
   font-size: 20px;
}

.dz-container {
   border: 2px dashed #0087F7;
   padding: 20px;
   margin-bottom: 20px;
   height: 160px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   cursor: pointer;
}

.dz-image {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   margin-top: 15px;
   margin-bottom: 15px;
}

.dz-image-container {
   width: 120px;
   height: 120px;
   background: #f0f0f0;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   margin: 10px;
   overflow: hidden;
   border-radius: 10px;
}

.dz-image-container img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   transition: filter 0.3s;
}

.dz-image-container:hover img {
   filter: blur(5px);
}

.dz-image-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 0;
   transition: opacity 0.3s;
}

.dz-image-container:hover .dz-image-overlay {
   opacity: 1;
}

.dz-close-icon {
   position: absolute;
   top: 5px;
   right: 5px;
   cursor: pointer;
   background-color: white;
   border-radius: 50%;
   padding: 2px;
   color: red;
   /* Close icon color */
}

/* :where(.css-dev-only-do-not-override-17seli4).ant-tooltip .ant-tooltip-inner {
   min-width: 1em;
   min-height: 32px;
   padding: 6px 8px;
   color: #fff;
   text-align: start;
   text-decoration: none;
   word-wrap: break-word;
   background-color: rgba(0, 0, 0, 0.85);
   border-radius: 6px;
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
   box-sizing: border-box;
   display: none;
} */

:where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
   border-color: none;
}
:where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error{
   border-color: green;
}
:where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item{
   height: 185px;
   margin:0;
   width:185px
}
.grid-item .start-col-3 .row-span-2 .ant-upload-wrapper .photographer .css-dev-only-do-not-override-17seli4 .ant-upload-picture-card-wrapper .ant-tooltip-inner {
   display: none;
}

.project-card {
   display: block;
   color: white;
   text-decoration: none;
   border-radius: 10px;
   padding: 20px;
   transition: transform 0.3s ease, box-shadow 0.3s ease;
   height: 110px;
   display: flex;
   align-items: center;
   justify-content: center;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-card:hover {
   transform: translateY(-5px);
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.orange {
   background: linear-gradient(135deg, #f7931f 0%, #f9b23c 100%);
}

.gray {
   background: linear-gradient(135deg, #6e6e6e 0%, #9c9c9c 100%);
}

.card-content {
   text-align: center;
}

.project-name {
   font-size: 18px;
   margin-bottom: 5px;
   font-weight: bold;
}

.due-date {
   font-size: 15px;
   font-weight: 300;
}

.photogarpher-dashboard {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
   gap: 20px;
   padding: 20px;
}

.left-side-card {
   width: 200px;
   height: 150px;
   border: 2px solid transparent;
   border-radius: 5px;
   overflow: hidden;
   cursor: pointer;
   margin-bottom: 10px;
   transition: border 0.3s ease;
 }
 
 .left-side-card.selected {
   border-color: #f7931f;
 }
 
 .card-image {
   width: 100%;
   height: 70%;
   object-fit: cover;
 }
 
 .job-id {
   text-align: center;
   margin: 5px 0;
   font-weight: bold;
 }

 .photographer-jobs-container {
   display: flex;
 }
 
 .left-side {
   display: flex;
   flex-direction: column;
   padding: 20px;
   max-width: 185px;
 }
 
 .right-side {
   flex-grow: 1;
   padding: 0px;
 }
 
 .right-side h2 {
   margin-top: 0;
 }

 .photographer-divider {
   width: 2px;
   background-color: #e0e0e0;
   margin: 0 20px;
 }
 
 :where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card, :where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card, :where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle, :where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle {
   display: flex;
   flex-wrap: wrap;
   height: 180px;
   width: 250px;
}

#full-view-components {
   width: 100% !important;
   margin-top: 0px;
}

/* React-slick(Executor screen carousel button css */
.slick-prev:before {
   content: "←";
   color: green !important;
   font-size: 25px !important;
   display: block;
   text-align: center;
   line-height: 50px;
}

.slick-next:before {
   content: "→";
   color: green !important;
   font-size: 25px !important;
   display: block;
   text-align: center;
   line-height: 50px;
}

.slick-active.slick-center {
   padding: 0;
   border: thick solid #f7931e !important;
}

.executor-image-slider .slick-current {
   padding: 0;
   border: thick solid #f7931e !important;
}

.view-item .slick-prev:before {
   content: "←";
   color: green !important;
   font-size: 30px !important;
   display: block;
   text-align: center;
}

.view-item .slick-next:before {
   content: "→";
   color: green !important;
   font-size: 30px !important;
   display: block;
   text-align: center;
}

.condition-disabled {
   cursor: not-allowed;
}
 
.reports-item .ant-table-tbody tr:nth-child(odd) {
   /* background-color: rgba(153, 153, 153, 0.2); */
   background-color: white;
}

.rooms-breadcrumb :where(.css-dev-only-do-not-override-17seli4).ant-breadcrumb {
   box-sizing: border-box;
   margin: -25px;
   margin-left: 32px;
   padding: -28px;
   color: rgba(0, 0, 0, 0.45);
   font-size: 14px;
   line-height: 1.5714285714285714;
   list-style: none;
   font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

:where(.css-dev-only-do-not-override-17seli4).ant-input-outlined.ant-input-disabled, :where(.css-dev-only-do-not-override-17seli4).ant-input-outlined[disabled] {
   color: black;
   background-color: rgba(0, 0, 0, 0.04);
   border-color: #d9d9d9;
   box-shadow: none;
   cursor: not-allowed;
   opacity: 1;
}

:where(.css-dev-only-do-not-override-17seli4).ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
   background: rgba(0, 0, 0, 0.04);
   color: black;
}

:where(.css-dev-only-do-not-override-17seli4).ant-picker .ant-picker-input >input[disabled] {
   background: transparent;
   color: black;
   cursor: not-allowed;
}

:where(.css-17seli4).ant-picker .ant-picker-input >input[disabled] {
   background: transparent;
   color: black;
   cursor: not-allowed;
}

:where(.css-17seli4).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item, :where(.css-17seli4).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item, :where(.css-17seli4).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item, :where(.css-17seli4).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
   height: 120px;
   margin: 0;
   width: 140px;
   border-color: green;
}

.ant-upload-picture-card-wrapper :where(.css-17seli4).ant-tooltip .ant-tooltip-inner {
   min-width: 1em;
   min-height: 32px;
   padding: 6px 8px;
   color: #fff;
   text-align: start;
   text-decoration: none;
   display: none;
   word-wrap: break-word;
   background-color: rgba(0, 0, 0, 0.85);
   border-radius: 6px;
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
   box-sizing: border-box;
}